import { tracking, _t } from "@bark/bark-core";

export default function () {

    "use strict";

    var checkAvailabilityModal,
        invalidFieldCount = 0,
        projectId = "",
        sourceType = "",
        sellerProfileId = 0,
        bpvsId = null,
        esdh
    ;

    function closeCheckAvailabilityModal() {
        // Buyer dashboard uses foundation
        if ($("#buyer-check-availability-modal").hasClass('reveal-modal')) {
            $("#buyer-check-availability-modal").foundation('reveal', 'close');
        } else {
            $("#buyer-check-availability-modal").modal('hide');
        }
        logEvent('hide_modal', sellerProfileId);
    }

    /**
     * Focuses the contact field, if it is empty, and the user is not on mobile
     */
    function focusContactField() {
        setSubmittedState(false);
    }

    /**
     * Logs an event
     * @param {string} eventName
     * @param {string} eventIdentifier
     * @param {string=seller_profile_message_modal} eventCategory
     */
    function logEvent(eventName, eventIdentifier, eventCategory) {
        if (esdh) {
            logExperimentSessionAction( {
                actionValue: eventIdentifier,
                experimentId: esdh,
                action: `check-availability-modal-${eventName}`,
                hashedProjectId: projectId
            });
        }
    }

    /**
     * Clears all validation error messages and classes
     */
    function resetContactValidationMessages() {
        $(".error-container", checkAvailabilityModal).addClass('d-none');
        $(".invalid-feedback", checkAvailabilityModal).hide();
        $(".is-invalid", checkAvailabilityModal).removeClass("is-invalid");
        invalidFieldCount = 0;
    }

    /**
     * Disable the submit and close buttons
     * @param {boolean} isSubmitting If true, disable submit buttons and show submitting status, otherwise reset
     */
    function setSubmittedState(isSubmitting) {
        $(".js-post-message .button-text", checkAvailabilityModal)[isSubmitting ? 'addClass' : 'removeClass']('d-none');
        $(".js-post-message", checkAvailabilityModal).attr("disabled", (isSubmitting ? true : false));
        $("button.close", checkAvailabilityModal)[isSubmitting ? 'addClass' : 'removeClass']('d-none');
        $(".js-post-message .spinner-border", checkAvailabilityModal)[isSubmitting ? 'removeClass' : 'addClass']('d-none');
    }

    /**
     * Shows a validation error by highlighting the affected field and displaying the associated invalid message. Also records an event
     * @param {string} fieldName The name of the field
     */
    function showContactValidationError(fieldName) {
        var field = $("[name=" + fieldName + "]", checkAvailabilityModal);
        field.addClass("is-invalid");
        Bark.addErrorAnimation(field);
        field.next(".invalid-feedback").show();
        invalidFieldCount++;
    }

    /**
     * Submit the contact form to the API endpoint
     */
    function submitCheckAvailabilityMessage() {
        $(".error-container", checkAvailabilityModal).addClass('d-none');
        var payload = {
            message: $("[name=message]", checkAvailabilityModal).val(),
            project_id: projectId,
            seller_profile_id: sellerProfileId,
            bpvs: bpvsId,
            source: sourceType,
            type: 'message'
        };
        Bark.api(
            'seller/contact',
            payload,
            function (data) {
                if (data.status) {
                    toggleConfirmationMessage(true);
                    logEvent('submit-success', payload.message.length);

                    tracking('displaying-prices', 'Submit Check availability', {
                        project_id: payload.project_id,
                        seller_id: payload.seller_profile_id
                    });

                    checkAvailabilityModal.trigger('contactsuccess');
                    $('html, body').animate({
                        scrollTop: 0
                    }, 250);

                    // Auto-close the modal in 2 seconds
                    window.setTimeout(function () {
                        closeCheckAvailabilityModal();
                    }, 2000);
                } else {
                    $(".error-container", checkAvailabilityModal).removeClass('d-none').text(_t('seller_public-profile_check-availability-modal:errors.generic-error'));
                }
                setSubmittedState(false);
            },
            function (error) {
                var errors = [];
                if (error.responseJSON && error.responseJSON.error && error.responseJSON.error.errors) {
                    for (var field in error.responseJSON.error.errors) {
                        errors.push(error.responseJSON.error.errors[field]);
                    }
                }
                if (!errors.length && error.responseJSON && error.responseJSON.message) {
                    errors.push(error.responseJSON.message);
                }
                $(".error-container", checkAvailabilityModal).removeClass('d-none').text(errors.join(' '));
                setSubmittedState(false);
            },
            'POST',
            Bark.apiVersionHeader('v2')
        );
    }

    /**
     * Shows the confirmation message
     * @param {boolean} show Whether to show the confirmation message
     */
    function toggleConfirmationMessage(show) {
        $(".contact-form-container", checkAvailabilityModal)[show ? 'addClass' : 'removeClass']('d-none');
        $(".confirmation-container", checkAvailabilityModal)[show ? 'removeClass' : 'addClass']('d-none');
        // Reset the form
        if (!show) {
            $("input,textarea", checkAvailabilityModal).each(function () {
                $(this).val($(this).data('original-value') || null);
            });
            resetContactValidationMessages();
        }
    }

    /**
     * Validates the contact form prior to submission.
     */
    function validateContactForm() {
        resetContactValidationMessages();
        setSubmittedState(true);
        // Reset this message in case it was changed by a previous validation attempt.
        var msg =  $("[name=message]", checkAvailabilityModal).val();

        if (msg.length < 30) {
            showContactValidationError("message");
            $(".error-container", checkAvailabilityModal).removeClass('d-none').text(_t('seller_public-profile_check-availability-modal:errors.message-too-short'));
            setSubmittedState(false);
            logEvent("validation_error_message", msg);
            return false;
        }
        return true;
    }

    $("document").ready(function () {
        checkAvailabilityModal = $("#buyer-check-availability-modal");

        // Contact button event handler
        $("body").on("click.show", ".js-check-availability-btn", function (e) {
            // Ensure that a seller profile ID data element has been set

            e.preventDefault();
            sellerProfileId = $(this).data("seller-profile-id") ? $(this).data("seller-profile-id").toString() : 0;
            bpvsId = $(this).data("bpvs") || null;
            projectId = $(this).data("project-id") || "";
            // todo remove esdh from here
            esdh = $(this).data('esdh') || esdh;
            sourceType = $(this).data("source-type") || "";

            if (sellerProfileId.length) {
                // Add the company name to the confirmation message text, if provided.
                var companyName = $(this).data('seller-name') || '';
                companyName = companyName.length ? ' to ' + companyName : '';
                $(".js-company-name", checkAvailabilityModal).text(companyName);

                // Clear any previous error messages
                resetContactValidationMessages();

                $("#buyer-check-availability-modal").modal('show');
                // Log an event
                logEvent('show_modal', sellerProfileId);

                tracking('displaying-prices', 'Check Availability CTA', {
                    project_id: projectId,
                    seller_id: sellerProfileId
                });

            } else {
                // This should never happen, so inform Bugsnag
                bugsnagClient.notify(new Error("js-check-availability-button.click:failed"), {
                    severity: "error"
                });
            }
            return false;
        });

        // Reset the form before the modal is shown
        checkAvailabilityModal.on("show.bs.modal.reset", function () {
            toggleConfirmationMessage(false);
        });

        // Submit button handler
        $(".js-post-message", checkAvailabilityModal).on("click", function (e) {
            if (validateContactForm()) {
                submitCheckAvailabilityMessage();
            }
            e.preventDefault();
            return false;
        });

        // Explicitly set the viewport height so that the modals will scroll as expected on mobile
        if(Bark.isMobile()) {
            // only do this for internal pages or pageloads with a project ID  so googlebot doesnt get cross at us
            if ($('.seller-public-profile-page').length < 1 || $('.seller-public-profile-page.has-back-to-quotes').length > 0) {
                setTimeout(function () {
                    var viewheight = $(window).height(),
                        viewwidth = $(window).width(),
                        viewport = document.querySelector("meta[name=viewport]");
                    viewport.setAttribute("content", "height=" + viewheight + ", width=" + viewwidth + ", initial-scale=1.0");
                }, 300);
            }
            // On Android, we need to scroll the field into view
            $("textarea", checkAvailabilityModal).focus(function(){
                if (($(window).height() < checkAvailabilityModal.height()) && !$('body').hasClass('ios')) {
                    $('html, body').animate({
                        scrollTop: $(this).offset().top - 40
                    }, 250);
                }
            });
        }

    });

}
