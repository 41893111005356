export default function () {
	var init = [];

	var current_country_id = null;
	var current_tier = null;

	init.push(function () {
		$('#mi-category').streamComplete({
			src: '/json/categories/autocomplete/',
			delay: 50,
			onsearch: function (results) {
				var elem = $('#mi-category');
				if (results.length === 1) {
					elem.data({ value: results[0].id });
					elem.data({ resultslist: null });
				} else {
					elem.data({ value: '' });
				}
				if (results.length > 1) {
					elem.data({ resultslist: results });
				}
				$('.streamcomplete-body').css('z-index', 10000000);
				$('.seller-dashboard-filter .new-error').hide();
			},
			onselect: function (item) {
				add_tier_category(item.id);
			},
		});

		$('#mi-category').click(function () {
			$(this).select();
		});

		update_list();
	});

	function validate_number(event) {
		if ($(event.target).val() < $(event.target).data('min')) {
			$(event.target).css('color', 'red');
			$(event.target).css('background-color', '#FFEEEE');
			$(event.target).data('valid', 'no');
			return;
		}

		if ($(event.target).val() > $(event.target).data('max')) {
			$(event.target).css('color', 'red');
			$(event.target).css('background-color', '#FFEEEE');
			$(event.target).data('valid', 'no');
			return;
		}

		$(event.target).css('color', '');
		$(event.target).css('background-color', '');
		$(event.target).data('valid', 'yes');
	}

	function numberWithCommas(x) {
		return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	}

	function select_country(event, new_country_id) {
		if (event != null) {
			event.preventDefault();
		}
		if (current_country_id != null) {
			$('#country-link-' + current_country_id).css('font-weight', 'normal');
			$('#country-table-' + current_country_id).hide();
		}
		$('#country-link-' + new_country_id).css('font-weight', 'bold');
		$('#country-table-' + new_country_id).fadeIn();
		current_country_id = new_country_id;
	}

	function update_list() {
		$('#tiers').html('Loading...');

		$.getJSON('/admin2/affiliatesGetTiers/', null, function (data) {
			var output = $('<div>');

			// Render country list at top of the page

			for (var i = 0; i < data.countries.length; i++) {
				if (i != 0) {
					output.append(' | ');
				}

				output.append(
					$('<a>', {
						href: '#',
						id: 'country-link-' + data.countries[i].id,
						onClick: 'select_country(event, ' + data.countries[i].id + ')',
					}).text(data.countries[i].name),
				);
			}

			// For each country render the affiliate table

			for (var i = 0; i < data.countries.length; i++) {
				var country_id = data.countries[i].id;

				var table = $('<table>', {
					class: 'table table-condensed table-bordered',
					style: 'display: none; margin-top: 20px',
					id: 'country-table-' + country_id,
				});
				var tr = $('<tr>');
				tr.append(
					$('<th>', {
						align: 'center',
						style: 'text-align: center',
					}).text('Tier'),
				);
				tr.append(
					$('<th>', {
						align: 'center',
						style: 'text-align: center',
					}).text('Event ID'),
				);
				tr.append(
					$('<th>', {
						align: 'center',
						style: 'text-align: center',
					}).text('Commission'),
				);
				tr.append(
					$('<th>', {
						align: 'center',
						style: 'text-align: center',
					}).text('Actions'),
				);
				table.append(tr);

				for (var a = 0; a < data.tiers.length; a++) {
					if (data.tiers[a].country_id != country_id) {
						continue;
					}

					var tr = $('<tr>');
					tr.append(
						$('<td>', {
							align: 'center',
							style: 'text-align: center',
						}).text(data.tiers[a].tier_id),
					);
					tr.append(
						$('<td>', {
							align: 'center',
							style: 'text-align: center',
							id: 'commission-' + country_id + '-' + data.tiers[a].tier_id + '-event-id',
						}).text(data.tiers[a].event_id),
					);
					tr.append(
						$('<td>', {
							align: 'center',
							style: 'text-align: center',
							id:
								'commission-' + country_id + '-' + data.tiers[a].tier_id + '-commission',
						}).text(data.tiers[a].commission),
					);
					tr.append(
						$('<td>', {
							align: 'center',
							style: 'text-align: center',
						})
							.append(
								$('<a>', {
									href: '#',
									onClick:
										'edit_tier(event, ' + country_id + ', ' + data.tiers[a].tier_id + ')',
								}).text('Edit'),
							)
							.append(' | ')
							.append(
								$('<a>', {
									href: '#',
									onClick:
										'edit_categories(event, ' +
										country_id +
										', ' +
										data.tiers[a].tier_id +
										')',
								}).text('Categories'),
							),
					);
					table.append(tr);
				}

				output.append(table);
			}

			output.append("<a href='#' onClick='add_tier(event)' class='btn'>Add New Tier</a>");

			$('#tiers').html(output);
			if (current_country_id == null) {
				select_country(null, data.countries[0]['id']);
			} else {
				select_country(null, current_country_id);
			}
		});
	}

	function edit_categories(event, country_id, tier_id) {
		if (event != null) {
			event.preventDefault();
		}

		current_tier = null;

		$('#categories-tier-modal-loading').show();
		$('#categories-tier-modal-saving').hide();
		$('#categories-tier-modal-editor').hide();
		$('#categories-tier-modal-footer').hide();
		$('#categories-tier-modal').modal('show');
		$('#categories-tier-error').hide();

		$.getJSON(
			'/admin2/affiliatesGetTier/' + country_id + '/' + tier_id + '/',
			null,
			function (data) {
				current_tier = data.tier;

				if (data.tier.categories.length == 0) {
					$('#categories-tier-current-categories').html('No current categories');
				} else {
					var output = $('<ul>', { style: 'text-align:left' });
					for (var i = 0; i < data.tier.categories.length; i++) {
						output.append($('<li>').text(data.tier.categories[i].name));
					}
					$('#categories-tier-current-categories').html(output);
				}

				$('#categories-tier-modal-loading').hide();
				$('#categories-tier-modal-editor').slideDown();
				$('#categories-tier-modal-footer').slideDown();
			},
		);
	}

	function edit_tier(event, country_id, tier_id) {
		event.preventDefault();

		current_tier = null;

		$('#edit-tier-modal-loading').show();
		$('#edit-tier-modal-saving').hide();
		$('#edit-tier-modal-editor').hide();
		$('#edit-tier-modal-footer').hide();
		$('#edit-tier-modal').modal('show');
		$('#edit-tier-error').hide();

		$.getJSON(
			'/admin2/affiliatesGetTier/' + country_id + '/' + tier_id + '/',
			null,
			function (data) {
				current_tier = data.tier;

				$('#edit-tier-id').val(data.tier.tier_id);
				$('#edit-tier-event-id').val(data.tier.event_id);
				$('#edit-tier-commission').val(data.tier.commission);

				$('#edit-tier-modal-loading').hide();
				$('#edit-tier-modal-editor').slideDown();
				$('#edit-tier-modal-footer').slideDown();
			},
		);
	}

	function add_tier(event) {
		event.preventDefault();

		$('#add-tier-modal-loading').hide();
		$('#add-tier-modal-saving').hide();
		$('#add-tier-modal-editor').show();
		$('#add-tier-modal-footer').show();
		$('#add-tier-modal').modal('show');
		$('#add-tier-error').hide();
	}

	function edit_tier_cancel(event) {
		event.preventDefault();
		current_tier = null;
		edit_tier_modal_hide();
	}

	function add_tier_cancel(event) {
		event.preventDefault();
		add_tier_modal_hide();
	}

	function edit_categories_cancel(event) {
		event.preventDefault();
		current_tier = null;
		edit_categories_modal_hide();
	}
	function edit_tier_save(event) {
		event.preventDefault();
		$('#edit-tier-modal-saving').show();
		$('#edit-tier-modal-editor').hide();
		$('#edit-tier-modal-footer').hide();
		$('#edit-tier-error').hide();

		const params = {
			event_id: $('#edit-tier-event-id').val(),
			commission: $('#edit-tier-commission').val(),
		};

		$.getJSON(
			'/admin2/affiliatesUpdateTier/' +
				current_tier.country_id +
				'/' +
				current_tier.tier_id +
				'/',
			params,
			function (data) {
				if (!data.success) {
					$('#edit-tier-error').text(
						'There was an error saving. Please check your values.',
					);
					$('#edit-tier-error').show();
					$('#edit-tier-modal-saving').hide();
					$('#edit-tier-modal-editor').show();
					$('#edit-tier-modal-footer').show();
					return;
				}

				$.getJSON(
					'/admin2/affiliatesGetTier/' +
						current_tier.country_id +
						'/' +
						current_tier.tier_id +
						'/',
					null,
					function (data) {
						$(
							'#commission-' +
								current_tier.country_id +
								'-' +
								current_tier.tier_id +
								'-event-id',
						).text(data.tier.event_id);
						$(
							'#commission-' +
								current_tier.country_id +
								'-' +
								current_tier.tier_id +
								'-commission',
						).text(data.tier.commission);

						edit_tier_modal_hide();
					},
				);
			},
		);
	}

	function add_tier_save(event) {
		event.preventDefault();
		$('#add-tier-modal-saving').show();
		$('#add-tier-modal-editor').hide();
		$('#add-tier-modal-footer').hide();
		$('#add-tier-error').hide();

		const params = {
			event_id: $('#add-tier-event-id').val(),
			commission: $('#add-tier-commission').val(),
		};

		$.getJSON(
			'/admin2/affiliatesAddTier/' +
				current_country_id +
				'/' +
				$('#add-tier-id').val().trim() +
				'/',
			params,
			function (data) {
				if (!data.success) {
					$('#add-tier-error').text(
						'There was an error saving. Please check your values.',
					);
					$('#add-tier-error').show();
					$('#add-tier-modal-saving').hide();
					$('#add-tier-modal-editor').show();
					$('#add-tier-modal-footer').show();
					return;
				}

				add_tier_modal_hide();
				update_list();
			},
		);
	}

	function edit_categories_modal_hide() {
		$('#categories-tier-modal-loading').show();
		$('#categories-tier-modal-saving').hide();
		$('#categories-tier-modal-editor').hide();
		$('#categories-tier-modal-footer').hide();
		$('#categories-tier-error').hide();
		$('#categories-tier-modal').modal('hide');
	}

	function edit_tier_modal_hide() {
		$('#edit-tier-modal-loading').show();
		$('#edit-tier-modal-saving').hide();
		$('#edit-tier-modal-editor').hide();
		$('#edit-tier-modal-footer').hide();
		$('#edit-tier-error').hide();
		$('#edit-tier-modal').modal('hide');
	}

	function add_tier_modal_hide() {
		$('#add-tier-modal-loading').show();
		$('#add-tier-modal-saving').hide();
		$('#add-tier-modal-editor').hide();
		$('#add-tier-modal-footer').hide();
		$('#add-tier-error').hide();
		$('#add-tier-modal').modal('hide');
	}

	function add_tier_category(category_id) {
		$('#categories-tier-modal-saving').show();
		$('#categories-tier-modal-editor').hide();
		$('#categories-tier-modal-footer').hide();
		$('#categories-tier-error').hide();

		if (!category_id) {
			$('#categories-tier-error').text('Please select a category.');
			$('#categories-tier-error').show();
			$('#categories-tier-modal-saving').hide();
			$('#categories-tier-modal-editor').show();
			$('#categories-tier-modal-footer').show();
			return;
		}

		const params = { category_id: category_id };

		$.getJSON(
			'/admin2/affiliatesAddTierCategory/' +
				current_tier.country_id +
				'/' +
				current_tier.tier_id +
				'/',
			params,
			function (data) {
				if (!data.success) {
					$('#categories-tier-error').text(
						'Error - maybe category not set up for this country.',
					);
					$('#categories-tier-error').show();
					$('#categories-tier-modal-saving').hide();
					$('#categories-tier-modal-editor').show();
					$('#categories-tier-modal-footer').show();
					return;
				}

				edit_categories(null, current_tier.country_id, current_tier.tier_id);
			},
		);
	}

	var sort_field = 'commission';
	var sort_dir = 'desc';

	function set_sort(event, field, dir) {
		event.preventDefault();
		sort_field = field;
		sort_dir = dir;
		refresh_tiers();
	}

	function refresh_tiers() {
		var output = $('<div>');
		var table = $('<table>', { class: 'affiliate-commission-table' });
		var thead = $('<thead>');
		var tr = $('<tr>');

		var th = $('<th>', { class: 'table-top-left', style: 'width:75%' });
		var a = null;

		if (sort_field == 'name' && sort_dir == 'asc') {
			a = $('<a>', {
				href: '#',
				onclick: 'set_sort(event, "name", "desc")',
			}).html("Category <span class='text-sm'>(asc)</span>");
		} else if (sort_field == 'name') {
			a = $('<a>', {
				href: '#',
				onclick: 'set_sort(event, "name", "asc")',
			}).html("Category <span class='text-sm'>(desc)</span>");
		} else {
			a = $('<a>', {
				href: '#',
				onclick: 'set_sort(event, "name", "asc")',
			}).html('Category');
		}

		tr.append(th.append(a));

		th = $('<th>');
		if (sort_field == 'commission' && sort_dir == 'desc') {
			a = $('<a>', {
				href: '#',
				onclick: 'set_sort(event, "commission", "asc")',
			}).html("Commission <span class='text-sm'>(desc)</span>");
		} else if (sort_field == 'commission') {
			a = $('<a>', {
				href: '#',
				onclick: 'set_sort(event, "commission", "desc")',
			}).html("Commission <span class='text-sm'>(asc)</span>");
		} else {
			a = $('<a>', {
				href: '#',
				onclick: 'set_sort(event, "commission", "desc")',
			}).html('Commission');
		}

		tr.append(th.append(a));

		table.append(thead.append(tr));

		var tbody = $('<tbody>');
		tr = $('<tr>');
		var td = $('<td>').text('Loading...');

		table.append(tbody.append(tr.append(td)));

		$('#affiliate-table').html(table);

		const parameters = {
			sort_dir: sort_dir,
			sort_field: sort_field,
			filter: $('#affiliate_filter').val().trim(),
		};

		$.getJSON('/affiliate-commission-ajax/', parameters, function (data) {
			tbody.empty();

			if (data.categories.length == 0) {
				tr = $('<tr>');
				td = $('<td>').text('No results found');
				tbody.append(tr.append(td));
			}

			for (var i = 0; i < data.categories.length; i++) {
				var category = data.categories[i];

				tr = $('<tr>');

				td = $('<td>');
				a = $('<a>', {
					href: '/' + category.default_marketing_landing_page + '/?popup=true',
					target: '_blank',
				}).text(category.name);
				tr.append(td.append(a));
				td = $('<td>', { class: 'text-center' }).text(
					`${window.Bark.LOCALE.currency_symbol}${category.commission}`,
				);
				tr.append(td);

				tbody.append(tr);
			}
		});
	}

	$('#affiliate_filter').on('keyup', refresh_tiers);
	refresh_tiers();
}
