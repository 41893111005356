export default function (firstRun) {
    "use strict"
    if (firstRun !== false) {
        firstRun = true;
    }
    const { Handlebars, browseFunctions, bugsnagClient } = window;

    let state = {
        options: null,
        spidh: null,
        avatarUrl: null,
        avatarColour: null,
        avatarDefaultClass: null,
        isElite: null,
        companyName: null,
    }

    let initialState = {
        options: null,
        spidh: null,
        avatarUrl: null,
        avatarColour: null,
        avatarDefaultClass: null,
        isElite: null,
        companyName: null,
    }


    const initialiseModal = (options) => {
        state.options = options;
    }

    const simulateInit = () => {
        let options = {
            domId: "tender-upsell-modal",
        }
        initialiseModal(options);
    }

    const resetModal = () => {
        state = initialState;
    }

    const showModal = () => {
        let toRow = Handlebars.compile($('#request-quote-to-row').html());
        let companyName = state.companyName
        let firstLetter = companyName.charAt(0) || " ";
        let avatarData = {
            avatar_url: state.avatarUrl,
            elite: state.isElite,
            default_letter: firstLetter,
            default_class: state.avatarDefaultClass,
        };
        let isTenderUpsell = true;

        let html = toRow({
            companyName,
            avatarData,
            isTenderUpsell
        });
        $('#tender-upsell-sellerrow').html(html);
        $(`#${state.options.domId}`).modal('show');
    }

    const hideModal = () => {
        $(`#${state.options.domId}`).modal('hide');
    }

    const requestQuote = () => {
        if(state.spidh) {
            browseFunctions.requestQuoteFromSeller(state.spidh);
        } else {
            bugsnagClient.notify(new Error(`Tried to request a quote from TenderBrowseUpsell with no seller profile ID`));
        }
    }


    const bindClickHandlers = () => {
        const $btnRequestQuote = $("#tender-upsell-modal .js-request-quote-tender-upsell");
        const $btnInvokeModal = $(".js-request-quote-tenderbrowse-invoke");
        const $btnCloseModal = $('#tender-upsell-modal .close.close-modal-cross');
        $btnInvokeModal.off('click.tenderbrowse').on('click.tenderbrowse', function () {
            let spidh = $(this).data("sellerProfileId") || null;
            let avatarUrl = $(this).data("avatarUrl") || null;
            let avatarDefaultClass = $(this).data("avatarDefaultClass") || getRandomAvatarClass();
            let isElite = $(this).data("isElite") || false;
            let companyName = $(this).data("companyName") || null;

            state.spidh = spidh;
            state.avatarUrl = avatarUrl;
            state.avatarDefaultClass = avatarDefaultClass;
            state.isElite = isElite;
            state.companyName = companyName;
            browseFunctions.tracking('Request Quote', {sellerProfileId: spidh});
            showModal();
        });

        $btnRequestQuote.off('click.rq').on('click.rq', function() {
            requestQuote();
            hideModal();
        });

        $btnCloseModal.off('click.close').on('click.close', (e) => {
            const sellerProfileId = $btnInvokeModal.data('sellerProfileId')
            browseFunctions.tracking('Close tender upsell', {sellerProfileId})
        });
    }

    const getRandomAvatarClass = () => {
        let classes = ["saffron-mango",
            "sunglo",
            "cerise-red",
            "camelot",
            "morning-glory",
            "cerulean",
            "fun-blue",
            "jelly-bean",
            "puerto-rico",
            "inch-worm",
            "perfume",
            "heliotrope",
            "darkened-pink"];
        // ~~ is doing the same as Math.floor
        return classes[~~(Math.random() * classes.length)];
    }


    const registerPartial = () => {
        Handlebars.registerPartial('avatar', $('#avatar').html());
    }

    if (firstRun) {
        registerPartial();
    }
    let options = {
        domId: "tender-upsell-modal",
    }
    initialiseModal(options);
    bindClickHandlers();
}
