import { getLang } from '@bark/bark-core/src/helpers/locale';

export default function () {
	var barkModalOpen = false;

	$(document).ready(function () {
		$('.comment-card .avatar-wrapper').click(function () {
			if (getLang() !== 'en') return;
			var videoSrc = $(this).data('src');

			barkModalOpen = ($('#whatIsBarkModal').data('bs.modal') || {})._isShown;

			if (videoSrc) {
				var videoModal = $('#videoModal');

				if (barkModalOpen) {
					$('#whatIsBarkModal').modal('hide');
				}

				videoModal.modal('show');
				videoModal.find('#video-iframe').attr('src', videoSrc);
			}
		});
	});

	$('#videoModal').on('hide.bs.modal', function (e) {
		$(this).find('#video-iframe').attr('src', '');
		if (barkModalOpen) {
			$('#whatIsBarkModal').modal('show');
		}
	});
}
