export default function () {
    $(window).resize(function() {
        allowNoPostcodeOnMobile();
    });

    function allowNoPostcodeOnMobile() {
        if ($('.search-button.mobile-only').is(':visible') && !$("#inlineBarkModal").is(':visible')) {
            $('#postcode_id').val('-1');
            $('#postcode_type').val('-1');
        }
    }

    $(document).ready(function () {
        allowNoPostcodeOnMobile();
    });
}
