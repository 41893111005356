import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { createClient } from "contentful/dist/contentful.browser.min";

const getFoundersAndAppend = (client) => {
    showLoading('#the-founders');
    const { Handlebars } = window;
    const founderTemplate = Handlebars.compile(document.getElementById('founder-template').innerHTML);

    const content_type = 'barkFounder';
    const order =  'fields.firstName';

    client.getEntries({
        content_type,
        order
    }).then((entries) => {
        entries.items.map((entry) => {
            let {firstName, lastName, descriptionRichText} = entry.fields;
            let profilePicUrl = entry.fields.profilePicture.fields.file.url;
            let description = documentToHtmlString(descriptionRichText)

            let founder = {
                name: firstName + ' ' + lastName,
                profilePicUrl,
                description
            }

            hideLoading('#the-founders');

            document.getElementById('founders').innerHTML += founderTemplate({founder});
        })
    })
}

const getTeamMembersAndAppend = (client) => {
    const { Handlebars } = window;

    const teamMemberTemplate = Handlebars.compile(document.getElementById('team-member-template').innerHTML);
    const employeeTypeId = 'barkEmployee';
    const order = 'fields.firstName';
    client.getEntries({
        content_type: employeeTypeId,
        order
    }).then((entries) => {
        entries.items.map((entry) => {
            let {firstName, jobTitle, lastName} = entry.fields;

            let profilePicUrl = entry.fields.profilePicture.fields.file.url;
            //let lastnameInitial = lastName.length > 0 ? lastName.charAt(0).toUpperCase() + name.slice(1) : ''

            let teamMember = {
                fullName: firstName + ' ' + lastName,
                jobTitle,
                profilePicUrl
            }

            hideLoading('#the-team');

            document.getElementById('employees').innerHTML += teamMemberTemplate({
                teamMember: teamMember
            });
        })
    })
}

const showLoading = (section) => {
    $(section + ' .loading-content').removeClass('d-none').addClass('d-flex');
}

const hideLoading = (section) => {
    $(section + ' .loading-content').addClass('d-none').removeClass('d-flex');
}

export default function(space, accessToken) {
    let client = createClient({space, accessToken});

    getFoundersAndAppend(client);
    getTeamMembersAndAppend(client);
}
