import { tracking, _l,_t } from "@bark/bark-core";
import {
    isUSTaxComplianceActive,
    continueToBusinessAddress,
    saveBusinessAddress,
    initializeGooglePlacesAutoComplete
} from "@bark/bark-core";

export default function () {
  var Bark = (window.Bark = window.Bark || {});

  var seller = (Bark.seller = Bark.seller || {});

  var create = (seller.create = seller.create || {});

  /**
   * The maximum number of autocomplete results
   * @type Number
   */
  var N_AUTOCOMPLETE_RESULTS = 5;

  var experiments = window.experiments || {};

  var userdata = {};

  var postcodeLabel = window.postcodeLabel || 'postcode';

  var maxRedirects = 4;
  var redirectChecks = 0;

  var srv = window.srv || '';
  /**
   * Track events with GTM
   * @param {string} eventName The name of the event
   * @param {object} data [optional] Any extra data to pass through (most is passed through already)
   */
  function tagManager(eventName, data) {
    if (!window.dataLayer) {
      return;
    }
    try {
      window.dataLayer.push(
        $.extend(
          {
            event: eventName,
            country_id: window.Bark.ENV.ccid || null,
            lang: window.Bark.ENV.lang || null,
            locale: window.Bark.ENV.locale || null,
            campaign: window.Bark.ENV.campaign || null,
            category_id: create.category_id,
            category_url: create.category_slug,
            is_nationwide: create.is_nationwide,
            is_remote: create.is_remote,
          },
          data || {},
        ),
      );
    } catch (ex) {
      window.bugsnagClient && window.bugsnagClient.notify(ex);
    }
  }

  function attachEventHandlers() {
    $('input[name=area-type]')
      .off('click.select,change.select')
      .on('click.select,change.select', function () {
        $('.area-type-container').removeClass('selected');
        $('.area-type-container input[name=area-type]:checked')
          .parent('.area-type-container')
          .addClass('selected');
        setLocationFieldsStatus();
      });

    $('#area-type-radius')
      .off('click.select')
      .on('click.select', function () {
          tracking(
          'organic-seller-signup',
          'Enter location - enable distance',
          { category_id: create.category_id },
        );
      });

    if ($('#area-type-nationwide').length > 0) {
      $('#area-type-nationwide')
        .off('click.select')
        .on('click.select', function () {
            tracking(
            'organic-seller-signup',
            'Enter location - enable natwionwide',
            { category_id: create.category_id },
          );
        });
    }

    $('#next-button-location')
      .off('click')
      .on('click', function () {
        $('.invalid-feedback').addClass('d-none');
        $('.is-invalid').removeClass('is-invalid');
        if (!locationIsValid()) {
          showPostcodeError();
          logEvent('validation_error', 'postcode', $('#postcode-lookup').val());
          logEvent('click', 'next', 'false');
          return false;
        }
        logEvent('click', 'next', 'true');
        tracking('organic-seller-signup', 'Enter location - success', {
          category_id: create.category_id,
        });
        $('.section-1').removeClass('active');
        $('.section-2').addClass('active');
        tracking('organic-seller-signup', 'Final details - view', {
          category_id: create.category_id,
        });
        $(document).scrollTop(0);
        if (!$('#name').val() && !Bark.isMobile()) {
          $('#name').focus();
        }
        Bark.setPageState(
          {
            step: 1,
            nationwide: $('#area-type-nationwide').is(':checked'),
            postcodeId: $('#postcode_id').val(),
            postcodeType: $('#postcode_type').val(),
            postcodeText: $('#postcode-lookup').val(),
            distanceFromLocation: $('#distance-from-location').val(),
          },
          '',
          true,
        );

        Bark.setPageState(
          {
            step: 2,
            nationwide: $('#area-type-nationwide').is(':checked'),
            postcodeId: $('#postcode_id').val(),
            postcodeType: $('#postcode_type').val(),
            postcodeText: $('#postcode-lookup').val(),
            distanceFromLocation: $('#distance-from-location').val(),
          },
          'step2',
        );
      });

    $('#next-button-profile')
      .off('click')
      .on('click', function () {
        logEvent('click', 'see leads');
        validateSignup();
      });

    $('#next-button-business-address')
      .off('click')
      .on('click', function () {
          logEvent('click', 'see leads');
          submitSignup();
      });

    if (isUSTaxComplianceActive()) {
        initializeGooglePlacesAutoComplete();
    }

    $('#back-to-location')
      .off('click')
      .on('click', function (e) {
        $('.section-2').removeClass('active');
        $('.section-1').addClass('active');
        logEvent('click', 'back');
        Bark.setPageState({}, '');
        e.preventDefault();
        return false;
      });

    $('.terms-link').on('click', function () {
      logEvent('click', 'terms');
    });

    // Handle the next/enter button on mobile keyboards
    $('input')
      .off('keypress.enter')
      .on('keypress.enter', function (e) {
        if (e.keyCode === Bark.consts.KEY_CODE_ENTER) {
          var visibleInputs = $('input:visible'),
            nextIndex = visibleInputs.index(this) + 1,
            nextInput = visibleInputs.eq(nextIndex);
          if (nextInput.length) {
            nextInput.focus();
          } else {
            $('button.btn').focus();
          }
        }
      });

    $(window).on('popstate', function (e) {
      setLocationFromState(e);
    });

    $('#distance-from-location')
      .off('change.select')
      .on('change.select', (e) => {
        tracking(
          'organic-seller-signup',
          'Enter location - change distance',
          {
            category_id: create.category_id,
            distance: $('#distance-from-location').val(),
          },
        );
      });

    if ($('#service-remote').length > 0) {
      $('#service-remote')
        .off('change.toggleRemote')
        .on('change.toggleRemote', () => {
          let toggle = $('#service-remote').prop('checked') ? 'enable' : 'disable';
          tracking(
            'organic-seller-signup',
            `Enter location - ${toggle} remote`,
            { category_id: create.category_id },
          );
        });
    }
  }

  /**
   * Displays the error message for a specific field
   * @param field
   * @param message
   */
  function displayValidationError(field, message) {
    $('#' + field + '-row-error')
      .html(message)
      .removeClass('d-none');
    $('#' + field).addClass('is-invalid');
    Bark.addErrorAnimation($('#' + field));
  }

  /**
   * Gets the appropriate location lookup URL based on whether we are passing a postcode
   * @param {boolean} postcodeOnly
   * @returns {string}
   */
  function getPostcodeLookupUrl(postcodeOnly) {
    if (typeof postcodeOnly === 'undefined') {
      postcodeOnly = false;
    }

    return postcodeOnly
      ? Bark.sprintf(
          '%s/%s-lookup/',
          Bark.ENV.api_hostname,
          'postcode',
        )
      : Bark.sprintf('%s/location/', Bark.ENV.api_hostname);
  }

  /**
   * Checks to see if the selected location values are valid
   * @returns {boolean}
   */
  function locationIsValid() {
    if ($('#area-type-nationwide').is(':checked')) {
      return true;
    }
    return $('#postcode_id').val() > 0;
  }

  /**
   * Logs a signup event
   * @param eventType The event type. One of: begin, click, validation_error, api_error, seller_exists, buyer_exists, lead_results, success
   * @param eventField If applicable, the related field
   * @param eventValue If applicable, the related field value
   */
  function logEvent(eventType, eventField, eventValue) {
    $.post('/api/seller-signup-event-log/', {
      signup_type: 'organic',
      event_type: eventType,
      event_field: eventField,
      event_value: eventValue,
    });
  }

  /**
   * Redirect to the seller page. This is done by scheduling a number of checks for leads, and redirecting based on those results.
   */
  function redirectToSellerPage() {
    try {
      window.sellerSignupRedirected = false;
      // wait 2 seconds before checking how many barks
      setTimeout(function () {
        tracking('organic-seller-signup', 'Signup - success', {
          category_id: create.category_id,
        });
        scheduleLeadCheck(1);
      }, 2000);
    } catch (e) {
      // Some sort of error occurred, just redirect to the dashboard
      logEvent('general_error', 'redirectToSellerPage', JSON.stringify(e));
      bugsnagClient.notify(e);
      Bark.recordHotjarEvent('signup-no-leads-error');
      location.href = _l('/sellers/home/');
    }
  }

  /**
   * Schedules a check to see if any leads have been identified. If so, redirect to the leads dashboard and cancel all other checks
   * @param seconds The number of seconds to wait.
   */
  function scheduleLeadCheck(seconds) {
    redirectChecks += 1;
    Bark.api(
      'seller/barks',
      {},
      function (barks) {
        // If they have any leads, take them to the lead dashboard, otherwise to the seller home dashboard
        if (barks.data && barks.data.items && barks.data.items.length) {
          logEvent('lead_results', true, redirectChecks);
          Bark.recordHotjarEvent('signup-has-leads');
          window.sellerSignupRedirected = true;
          location.href = _l('/sellers/dashboard/?new=org');
        } else {
          if (redirectChecks <= maxRedirects) {
            // wait another second before checking again
            setTimeout(function () {
              scheduleLeadCheck(seconds);
            }, seconds * 1000);
          } else {
            // we have tried enough, just redirect to the home page
            window.setTimeout(function () {
              logEvent('lead_results', 'false', maxRedirects + 1);
              Bark.recordHotjarEvent('signup-no-leads');
              location.href = _l('/sellers/home/?noleads=1');
            }, seconds * 1000);
          }
        }
      },
      function (e) {
        logEvent('api_error', 'scheduleLeadCheck', JSON.stringify(e));
        Bark.recordHotjarEvent('signup-no-leads-error');
        bugsnagClient.notify(e);
        window.sellerSignupRedirected = true;
        location.href = _l('/sellers/home/');
      },
      'GET',
      Bark.apiVersionHeader('v2'),
    );
  }

  /**
   * Enables or disables the location fields based on the category type and radio button selection.
   */
  function setLocationFieldsStatus() {
    var isDisabled = true;
    if ($('#area-type-radius').attr('type') === 'hidden') {
      // Not a nationwide category
      isDisabled = false;
    } else if ($('#area-type-radius').prop('checked')) {
      // Radius radio button is selected
      isDisabled = false;
    }
    $('#postcode-row-error').addClass('d-none');
    $('#postcode-lookup').removeClass('is-invalid');
    $('.area-input-radius')
      .prop('disabled', isDisabled)
      [isDisabled ? 'addClass' : 'removeClass']('disabled');
  }

  function setLocationFromState(e) {
    var hash = location.hash.replace('#', '');

    if (hash === 'step2' || hash === 'step3') {
      $('.section-1').removeClass('active');
      $('.section-2').addClass('active');
      $('.section-3').removeClass('active');
      $('#postcode-lookup').data('streamcomplete').close();
      tracking('organic-seller-signup', 'Final details - view', {
        category_id: create.category_id,
      });
    } else {
      $('.section-1').addClass('active');
      $('.section-2').removeClass('active');
      $('.section-3').removeClass('active');
      if (!Bark.isMobile() && !$('#postcode_id').val()) {
        $('#postcode-lookup').focus();
      }
      tracking('organic-seller-signup', 'Enter location - view', {
        category_id: create.category_id,
      });
      if ($('#service-remote').length > 0) {
        let toggle = $('#service-remote').prop('checked') ? 'enable' : 'disable';
        tracking(
          'organic-seller-signup',
          `Enter location - ${toggle} remote`,
          { category_id: create.category_id },
        );
        tracking(
          'organic-seller-signup',
          'Enter location - view remote toggle',
          { category_id: create.category_id },
        );
      }
      if ($('#area-type-nationwide').length > 0) {
        tracking(
          'organic-seller-signup',
          'Enter location - view nationwide',
          { category_id: create.category_id },
        );
      }
    }

    // restore any previous selections stored in the state
    var state = history.state || (e && e.originalEvent ? e.originalEvent.state : null);
    try {
      if (state) {
        if (state.nationwide) {
          $('#area-type-nationwide').prop('checked', true).trigger('click');
        } else {
          $('#area-type-radius').prop('checked', true).trigger('click');
          $('#postcode_id').val(state.postcodeId);
          $('#postcode_type').val(state.postcodeType);
          $('#postcode-lookup').val(state.postcodeText);
          $('#distance-from-location').val(state.distanceFromLocation);
        }
      }
    } catch (ignore) {
      // ignore
    }
  }

  function showPostcodeError() {
    $('#postcode-lookup').addClass('is-invalid');
      let errorMessage = _t("seller_signup:profile-section.form.inputs.postcode-lookup.errors.empty", {
          "postcode_label": postcodeLabel
      });

      if ($('#postcode-lookup').val()) {
      errorMessage = _t("seller_signup:profile-section.form.inputs.postcode-lookup.errors.selecting-dropdown", {
          "postcode_label": postcodeLabel
      });
    }
    $('#postcode-row-error').html(errorMessage).removeClass('d-none');
    Bark.addErrorAnimation($('#postcode-lookup'));
    tracking('organic-seller-signup', 'Enter location - fail', {
      category_id: create.category_id,
      fail_reason: 'postcode',
    });
  }

  /**
   * Actually perform the save now
   */
  function submitSignup() {
    var isNationwide = $('#area-type-nationwide').is(':checked'),
      isRemote = $('#service-remote').is(':checked'),
      payload = {
        userdata: userdata,
        location: {
          type: isNationwide ? 'nationwide' : 'radius',
          name: isNationwide ? '' : $('#postcode-lookup').val(),
          city_type: isNationwide ? '' : $('#postcode_type').val(),
          id: isNationwide ? '' : $('#postcode_id').val(),
          radius: isNationwide ? '' : $('#distance-from-location').val(),
          remote: isNationwide || isRemote ? '1' : '0',
        },
        maincategoryid: seller.create.category_id,
        experiments: experiments,
      };
    tagManager('seller-signup:submit:initiate');
    // Use Bark.json as it has CSRF built in
    Bark.showLoading(null, null, _t('seller_signup:profile-section.creating-account'));
    setTracking(payload);
    Bark.json({
      url: '/sellers/save-new-account/',
      data: { payload: JSON.stringify(payload) },
    })
      .done(function (e) {
        if (e.result === 'OK') {
          // Store the login token
          tagManager('seller-signup:submit:successful');

          tracking(
            'organic-seller-signup',
            'Final details - success',
            { category_id: create.category_id },
          );
          window.Bark.ENV.JWT = e.data.jwt;
          if (isUSTaxComplianceActive()) {
              saveBusinessAddress();
          }
          Bark.showLoading();
          logEvent('success', '', srv);
          Bark.recordHotjarEvent('signup-success');
          Bark.recordHotjarFormStatus(true);
          redirectToSellerPage();
          return;
        } else if (e.data.code === Bark.consts.EX_EMAIL_EXISTS_IN_SELLERS) {
          Bark.hideLoading();
          Bark.confirmModal(
            _t("seller_signup:profile-section.form.inputs.email.errors.seller-already-exist"),
            function () {
              Bark.showLoading();
              location.href = _l('/login');
            },
            null,
            _t("seller_signup:profile-section.log-in-button"),
          );
          logEvent('seller_exists', 'email', $('#email').val());
          tagManager('seller-signup:submit:error:seller_exists');
          Bark.recordHotjarEvent('signup-failure');
          Bark.recordHotjarFormStatus(false);
          return;
        } else if (e.data.code === Bark.consts.EX_EMAIL_EXISTS_IN_BUYERS) {
          Bark.hideLoading();
          Bark.confirmModal(
            _t("seller_signup:profile-section.form.inputs.email.errors.buyer-already-exist"),
            function () {
              Bark.showLoading();
              var params = decodeURIComponent(window.location.search.substring(1)).split(
                '&',
              );
              params.push(
                'area-type=' +
                  ($('#area-type-nationwide').prop('checked') ? 'nationwide' : 'radius'),
              );
              params.push('distance-from-location=' + $('#distance-from-location').val());
              params.push(
                'postcode-lookup=' + encodeURIComponent($('#postcode-lookup').val()),
              );
              params.push('postcode_id=' + $('#postcode_id').val());
              params.push('postcode_type=' + $('#postcode_type').val());
              params.push('company-name=' + encodeURIComponent($('#company-name').val()));
              params.push('website=' + encodeURIComponent($('#website').val()));
              var redirectUrl = Bark.sprintf(
                '%s//%s%s?%s#step2',
                location.protocol,
                location.host,
                location.pathname,
                params.join('&'),
              );
                location.href = _l(Bark.sprintf(
                `/login/?dest_url=%s&ea=%s`,
                encodeURIComponent(redirectUrl),
                encodeURIComponent($('#email').val()),
              ));
            },
            null,
            _t("seller_signup:profile-section.log-in-button"),
          );
          logEvent('buyer_exists', 'email', $('#email').val());
          tagManager('seller-signup:submit:error:buyer_exists');
          Bark.recordHotjarEvent('signup-failure');
          Bark.recordHotjarFormStatus(false);
          return;
        }
        Bark.hideLoading();
          Bark.alertModal(e.result, _t('seller_signup:profile-section.close-button'));
      })
      .fail(function () {
        Bark.hideLoading();
            Bark.alertModal(_t('seller_signup:profile-section.form.errors.failed-to-save'), _t('seller_signup:profile-section.close-button'));
        tagManager('seller-signup:submit:error:unknown');
        Bark.recordHotjarEvent('signup-failure');
        Bark.recordHotjarFormStatus(false);
      });
  }

  /**
   * Validate the email address externally.
   * If passes, moves on to phone number validation.
   */
  function validateEmailIfPresent() {
    // If the email address field is missing because the user is logged in as a buyer, skip validation and move on
    if ($('#buyer_logged').length || !userdata.email.length) {
      validatePhoneIfPresent();
      return;
    }

    $('#email').email_validator({
      success: function (data) {
        if (data.is_valid) {
          // Check to see if the email address is already in use in Bark
          Bark.validate.barkEmail(userdata.email, function (isValid, err) {
            if (!isValid && err) {
              logEvent('validation_error', 'email_local_failure', userdata.email);
              displayValidationError('email', err);
              Bark.hideLoading();
            } else {
              validatePhoneIfPresent();
            }
          });
        } else {
          var emailErrorMsg = _t("seller_signup:profile-section.form.inputs.email.errors.invalid");

          if (data.did_you_mean) {
            emailErrorMsg = Bark.sprintf(
              'Did you mean <a href="#" class="email-did-you-mean">%s</a>?',
              data.did_you_mean,
            );
            logEvent('validation_error', 'email_external_suggestion', data.did_you_mean);
          } else {
            logEvent('validation_error', 'email_external', $('#email').val());
          }
          displayValidationError('email', emailErrorMsg);

          if (data.did_you_mean) {
            $('.email-did-you-mean')
              .off('click')
              .on('click', function (e) {
                logEvent('suggestion_accepted', userdata.email, data.did_you_mean);
                $('#email').val(data.did_you_mean);
                $('#email-row-error').addClass('d-none');
                $('#email').removeClass('is-invalid');
                e.preventDefault();
                return false;
              });
          }

          Bark.hideLoading();
        }
      },
      error: function () {
        displayValidationError('email', _t("seller_signup:profile-section.form.inputs.email.errors.invalid"));
        logEvent('validation_error', 'email_external', userdata.email);
        Bark.hideLoading();
      },
    });
  }

  /**
   * If the phone number field is present, validate it with an external service.
   * If passes, moves on to check for contact info in the name fields
   */
  function validatePhoneIfPresent() {
    if (
      !$('#phone').length ||
      ($('#phone.optional').length && !$('#phone').val().length)
    ) {
      validateCompanyName();
      return;
    }

    Bark.validate.telephone(
      userdata.tel,
      function (responseObject) {
        if (responseObject.status) {
          userdata.phone_type = responseObject.lineType;
          validateCompanyName();
        } else {
          displayValidationError('phone', _t("seller_signup:profile-section.form.inputs.phone-number.errors.invalid"));
          logEvent('validation_error', 'phone', userdata.tel);
          Bark.hideLoading();
        }
      },
      true,
    );
  }
  /**
   * Check name fields to make sure they dont contain any contact info.
   * If passes, moves on to final form submission
   */
  function validateCompanyName() {
    Bark.api(
      '/contentfilter/contactdetails',
      {
        contents: {
          'company-name': userdata.companyname,
          name: userdata.name,
        },
      },
      function (data) {
        if (data.status) {
            if (isUSTaxComplianceActive()){
                Bark.hideLoading();
                continueToBusinessAddress();
                initializeBusinessAddress();
            } else {
                submitSignup();
            }
        } else {
          let failed = data.failures;
          for (var i = 0; i < failed.length; i++) {
            let fieldname = failed[i];
            displayValidationError(
              fieldname,
              _t("seller_signup:profile-section.form.inputs.company-name.errors.includes-contact")
            );
            logEvent('validation_error', fieldname);
            Bark.addErrorAnimation($('#' + fieldname));
            Bark.hideLoading();
          }
        }
      },
      function (error) {
        window.bugsnagClient.notify(error);
          if (isUSTaxComplianceActive()){
              Bark.hideLoading();
              continueToBusinessAddress();
              initializeBusinessAddress();
          } else {
              submitSignup();
          }
      },
      'POST',
      Bark.apiVersionHeader('v1'),
    );
  }

  function initializeBusinessAddress() {
      $('.section-2').removeClass('active');
      $('.section-3').addClass('active');
      $('#back-to-profile-section')
          .off('click')
          .on('click', function (e) {
              $('.section-3').removeClass('active');
              $('.section-2').addClass('active');
              Bark.setPageState({}, '');
              e.preventDefault();
              return false;
          });

      Bark.setPageState(
          {
              step: 1,
              nationwide: $('#area-type-nationwide').is(':checked'),
              postcodeId: $('#postcode_id').val(),
              postcodeType: $('#postcode_type').val(),
              postcodeText: $('#postcode-lookup').val(),
              distanceFromLocation: $('#distance-from-location').val(),
          },
          '',
          true,
      );

      Bark.setPageState(
          {
              step: 2,
              nationwide: $('#area-type-nationwide').is(':checked'),
              postcodeId: $('#postcode_id').val(),
              postcodeType: $('#postcode_type').val(),
              postcodeText: $('#postcode-lookup').val(),
              distanceFromLocation: $('#distance-from-location').val(),
          },
          'step2',
          true
      );

      Bark.setPageState(
          {
              step: 3,
              nationwide: $('#area-type-nationwide').is(':checked'),
              postcodeId: $('#postcode_id').val(),
              postcodeType: $('#postcode_type').val(),
              postcodeText: $('#postcode-lookup').val(),
              distanceFromLocation: $('#distance-from-location').val(),
          },
          'step3'
      );
  }

  /**
   * Validates all of the signup form fields prior to submission.
   * Note that validation is from the bottom up in order for focus to fall on the first error field
   */
  function validateSignup() {
    var hasInvalidFields = false;
    Bark.showLoading();
    $('.invalid-feedback').addClass('d-none');
    $('.is-invalid').removeClass('is-invalid');
    userdata = {
      companyname: ($('#company-name').val() || '').trim(),
      name: ($('#name').val() || '').trim(),
      email: ($('#email').val() || '').trim(),
      website: ($('#website').val() || '').trim(),
      tel: ($('#phone').val() || '').trim(),
    };

    // Ensure that a location has been selected
    if (!locationIsValid()) {
      // take the user back to the location step and display an error
      showPostcodeError();
      $('#back-to-location').click();
      Bark.hideLoading();
      return false;
    }

    // Basic website validation
    if (userdata.website) {
      userdata.website = userdata.website.toLowerCase();
      if (
        !userdata.website.match(
          /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
        )
      ) {
        displayValidationError('website', _t("seller_signup:profile-section.form.inputs.website.errors.invalid"));
        hasInvalidFields = true;
        logEvent('validation_error', 'website', userdata.website);
      }
    }

    // Basic phone validation
    if ($('#phone').length && !$('#phone.optional').length) {
      if (!userdata.tel || !userdata.tel.length) {
        displayValidationError('phone', _t("seller_signup:profile-section.form.inputs.phone-number.errors.empty"));
        hasInvalidFields = true;
        $('#email-row-error').html();
        logEvent('validation_error', 'phone', userdata.tel);
      }
    }

    // Basic email validation
    if ($('#email').length) {
      if (
        !userdata.email ||
        !userdata.email.length ||
        !Bark.validateEmailFormat(userdata.email)
      ) {
        displayValidationError('email',  _t("seller_signup:profile-section.form.inputs.email.errors.invalid"));
        hasInvalidFields = true;
        $('#email-row-error').html();
        logEvent('validation_error', 'email_local', userdata.email);
      }
    }

    // Name cannot be empty
    if ($('#name').length && !userdata.name) {
      displayValidationError('name', _t("seller_signup:profile-section.form.inputs.name.errors.empty"));
      hasInvalidFields = true;
      logEvent('validation_error', 'name');
      Bark.addErrorAnimation($('#name'));
    }

    // If any validation has failed up to this point, stop before hitting any external APIs
    if (hasInvalidFields) {
      Bark.hideLoading();
      return false;
    }

    validateEmailIfPresent();
  }

  $('document').ready(function () {
    attachEventHandlers();
    if (Bark.getUrlParam('area-type') === 'nationwide') {
      $('#area-type-nationwide').prop('checked', true).trigger('click');
    } else {
      $('#area-type-radius').prop('checked', true).trigger('click');
    }
    setLocationFieldsStatus();
    var fields = [
      'distance-from-location',
      'postcode-lookup',
      'postcode_id',
      'postcode_type',
      'company-name',
      'website',
      'phone',
    ];
    for (var field in fields) {
      if (fields.hasOwnProperty(field)) {
        if (Bark.getUrlParam(field)) {
          $('#' + field).val(Bark.getUrlParam(field));
        }
      }
    }
    $('#postcode-lookup').streamComplete({
      src: getPostcodeLookupUrl(false),
      requestmethod: 'post',
      delay: 10,
      usestandardsyntax: true,
      extraAttributes: {'data-cy': 'seller-signup-form-location-results'},
      onclose: function () {
        $(this).removeClass('results-list-showing');
      },
      preprocessresults: function (data) {
        if (data.length > N_AUTOCOMPLETE_RESULTS) {
          data = data.slice(0, N_AUTOCOMPLETE_RESULTS);
        }

        for (var i = 0; i < data.length; i++) {
          var x = data[i];
          x.label = x.name;
        }

        return data;
      },
      onopen: function (data) {
        if (data.length) {
          $(this).addClass('results-list-showing');
        }
      },
      onresponse: function (responses) {
        if (responses.data.length === 1) {
          var item = responses.data[0];
          $('#postcode_id').val(item.id);
          $('#postcode_type').val(item.type);
        } else {
          $('#postcode_id').val(null);
          $('#postcode_type').val(null);
        }
      },
      onselect: function (item) {
        $('#postcode_id').val(item.id);
        $('#postcode_type').val(item.type);
        $('#postcode-row-error').addClass('d-none');
        $('#postcode-lookup').removeClass('is-invalid');
      },
      onbeforesearch: function (payload) {
        payload.coid = Bark.ENV.ccid;
      },
      onbeforeajaxsearch: function () {
        var sc = $(this).data('streamcomplete');
        sc.results = [];
      },
      onfocusout: function () {
        if (!$(this).val()) {
          $('#postcode_id').val(null);
          $('#postcode_type').val(null);
        }
      },
    });
    setLocationFromState();
    logEvent('begin', null, srv);
    tagManager('seller-signup:begin');
    Bark.triggerHotjarRecording('seller_signup_conversion');
  });

  /**
   * set tracking
   */
  function setTracking(payload) {
    payload['tracking'] = {
      trk_event_source_url: Bark.safeUrl(document?.location?.href),
      trk_client_user_agent: navigator?.userAgent,
    };
  }
}
