import { tracking } from "@bark/bark-core";

export const isRequestFromSellerProfileActive = () => {
    return $(".js-request-quote-from-profile-invoke").length > 0;
};

export const bindRequestFromSellerProfileEvents = () => {
    $('.cat-select-request-quote-fp').off('click.selectlink').on('click.selectlink', (e) => {
        $("#request-quote-seller-category-modal").modal('show');
        tracking(
            'Buyer - Public profile',
            'Category selection - view',
            {
                categoryName: $(e.currentTarget).data('category-name'),
                categoryId: $(e.currentTarget).data('category-id')
            }
        );
    });

    $('.trigger-request-quote-fp').off('click.requestQuote').on('click.requestQuote', (e) => {
        $('#category_id').val($(e.currentTarget).data('category-id'));
        $('#postcode_id').val('-1');
        $('#postcode_type').val('-1');
        $('#category_name_hidden').val('');
        $('#bark_mode').val('from-public-profile');
        window.Bark.set_cookie('publicProfileIdhBarkSrc', $('.js-request-quote-from-profile-invoke').data('seller-profile-id'), 1, '/');
        window.Bark.set_cookie('publicProfileNameBarkSrc', $('.js-request-quote-from-profile-invoke').data('company-name'), 1, '/');

        tracking(
            'Buyer - Public profile',
            'Category selection skipped - continue',
            {
                categoryName: $(e.currentTarget).data('category-name'),
                categoryId: $(e.currentTarget).data('category-id')
            }
        );
        $('#bark_submit').trigger('click');
    });

    $('.selected-trigger-request-quote-fp').off('click.multipleRequestQuote').on('click.multipleRequestQuote', (e) => {
        $("#request-quote-seller-category-modal").modal('hide');
        $('#category_id').val($('input[name=request-quote-fsp-category]:checked').val());
        $('#postcode_id').val('-1');
        $('#postcode_type').val('-1');
        $('#category_name_hidden').val('');
        $('#bark_mode').val('from-public-profile');
        window.Bark.set_cookie('publicProfileIdhBarkSrc', $('.js-request-quote-from-profile-invoke').data('seller-profile-id'), 1, '/');
        window.Bark.set_cookie('publicProfileNameBarkSrc', $('.js-request-quote-from-profile-invoke').data('company-name'), 1, '/');

        tracking(
            'Buyer - Public profile',
            'Category selection - continue',
            {
                categoryName: $(e.currentTarget).data('category-name'),
                categoryId: $(e.currentTarget).data('category-id')
            }
        );
        $('#bark_submit').trigger('click');
    });


    $('#request-quote-seller-category-modal .close-modal-cross').off('click.closeModal').on('click.closeModal', (e) => {
        $('.modal').modal('hide');
        tracking(
            'Buyer - Public profile',
            'Category selection - close'
        );
    });

    // Click a checkbox when its container is clicked on.
    $("#request-quote-seller-category-modal .inline-bark-q.can-highlight")
        .unbind("click.select")
        .on("click.select", function (e) {
            e.stopPropagation();
            e.preventDefault();

            // Only run when the div or label is clicked, not when one of the div's other child elements is clicked.
            // For example, when a <label for="..."> is clicked, it also triggers a click on the <input name="...">.
            var isParentEl =
                e.target.tagName === "DIV" &&
                e.target.classList.contains("inline-bark-q");
            var isLabel =
                e.target.tagName === "LABEL" &&
                e.target.classList.contains("inline-bark-q-label");
            if (!isParentEl && !isLabel) {
                return;
            }

            var errCont = $(this).closest(".new-error");
            var radiosCont = $(this).closest(".inline-bark-q-radio");

            if (errCont.length || radiosCont.length) {
                // If there was an error, e.g. the user tried to continue with the "Other" value not being filled in,
                // hide the error message when another option has been selected.
                $(".inline-bark-modal-errors").addClass("hide");
            }

            if (radiosCont.length) {
                // Remove errors whenever a radio is checked.
                radiosCont
                    .closest(".bark-modal-question")
                    .find(".new-error")
                    .removeClass("new-error");
            }

            errCont.removeClass("new-error");

            var selectRadios = $(
                "input[type=radio]",
                this
            ).toArray();
            if (selectRadios.length !== 1) {
                throw new Error(
                    "Expected 1 radio element, but there are: " +
                    selectRadios.length
                );
            }

            selectRadios.forEach(function (selectRadio) {
                switch (selectRadio.type) {
                    case "radio":
                        selectRadio.checked = true;
                        break;
                    default:
                        throw new Error(
                            "Unsupported element type: " +
                            selectRadio.type
                        );
                }

                selectRadio.focus();
            });
        });
}


