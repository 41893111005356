import {_t, BuyerProjectInteractionTracking} from "@bark/bark-core";

var contactInfoRequired = false,
    messageSellerModal,
    invalidFieldCount = 0,
    phoneToValidate = "",
    projectId = "",
    emailToValidate = "",
    experimentsTrackingHandler,
    sellerProfileId = 0;

/**
 * Focuses the contact field, if it is empty, and the user is not on mobile
 */
function focusMessageField() {
    if (!Bark.isMobile()) {
        $("[name=message]", messageSellerModal).focus();
    }
}

/**
 * Logs an event
 * @param {string} eventName
 * @param {string} eventIdentifier
 * @param {string=seller_profile_message_modal} eventCategory
 */
function logEvent(eventName, eventIdentifier, eventCategory) {
    try {
        eventCategory = eventCategory || 'seller_profile_message_modal';
        $.post(
            "/api/event-log/",
            {
                event_category: eventCategory,
                event_name: eventName,
                event_id: eventIdentifier
            }
        );
    } catch (e) {
        bugsnagClient.notify(e);
    }
}

function initExperimentTracking({sellerProfileId, projectId, sourceType}) {
    experimentsTrackingHandler = BuyerProjectInteractionTracking();
    experimentsTrackingHandler.initialise(); // keeping it agnostic here
    experimentsTrackingHandler.setSpid(sellerProfileId);
    experimentsTrackingHandler.setPid(projectId);
    experimentsTrackingHandler.setPagename(sourceType);
}

function logExperimentEvent(actionPartialName) {
    let sourceType = $('.seller-public-profile-page').length > 0 ? 'seller-profile-' : '';
    experimentsTrackingHandler?.submitExpActWithVal(`${sourceType}send-seller-email-modal-${actionPartialName}`, {projectId, sellerProfileId: sellerProfileId});
}

/**
 * Clears all validation error messages and classes
 */
function resetMessageValidationMessages() {
    $(".error-container", messageSellerModal).addClass('d-none');
    $(".invalid-feedback", messageSellerModal).hide();
    $(".is-invalid", messageSellerModal).removeClass("is-invalid");
    invalidFieldCount = 0;
}

/**
 * Disable the submit and close buttons
 * @param {boolean} isSubmitting If true, disable submit buttons and show submitting status, otherwise reset
 */
function setSubmittedState(isSubmitting) {
    $(".js-post-message .button-text", messageSellerModal)[isSubmitting ? 'addClass' : 'removeClass']('d-none');
    $(".js-post-message", messageSellerModal).attr("disabled", (isSubmitting ? true : false));
    $("button.close", messageSellerModal)[isSubmitting ? 'addClass' : 'removeClass']('d-none');
    $(".js-post-message .spinner-border", messageSellerModal)[isSubmitting ? 'removeClass' : 'addClass']('d-none');
}

/**
 * Shows the confirmation message
 * @param {boolean} show Whether to show the confirmation message
 */
function toggleConfirmationMessage(show) {
    $(".message-form-container", messageSellerModal)[show ? 'addClass' : 'removeClass']('d-none');
    $(".confirmation-container", messageSellerModal)[show ? 'removeClass' : 'addClass']('d-none');

    // Reset the form
    if (!show) {
        $("input,textarea", messageSellerModal).each(function () {
            $(this).val($(this).data('original-value') || null);
        });

        // Only show the contact fields if not tied to a project
        contactInfoRequired = !projectId.length;
        $(".contact-info-row,.seller-container")[contactInfoRequired ? "removeClass" : "addClass"]("d-none");
        if (contactInfoRequired) {
            $(".seller-container").removeClass('d-flex').addClass('d-none');
        } else {
            $(".seller-container").addClass('d-flex').removeClass('d-none');
        }
    }
}

/**
 * Shows a validation error by highlighting the affected field and displaying the associated invalid message. Also records an event
 * @param {string} fieldName The name of the field
 */
function showMessageValidationError(fieldName) {
    var field = $("[name=" + fieldName + "]", messageSellerModal);
    field.addClass("is-invalid");
    Bark.addErrorAnimation(field);
    field.next(".invalid-feedback").show();
    invalidFieldCount++;
}

/**
 * Submit the message form
 */
function submitMessageForm() {
    $(".error-container", messageSellerModal).addClass('d-none');
    Bark.api(
        'seller/contact',
        {
            message: $("[name=message]", messageSellerModal).val(),
            project_id: projectId,
            name: $("[name=name]", messageSellerModal).val(),
            email: $("[name=email]", messageSellerModal).val(),
            phone: $("[name=phone]", messageSellerModal).val(),
            seller_profile_id: sellerProfileId,
            type: 'message'
        },
        function (data) {
            if (data.status) {
                toggleConfirmationMessage(true);
                logExperimentEvent('success');

                // Auto-close the modal in 2 seconds
                window.setTimeout(function () {
                    $("#messageSellerModal").modal("hide");
                }, 2000);
            } else {
                logExperimentEvent('fail');
                $(".error-container", contactSellerModal).removeClass('d-none').text(_t('public_seller-profile:message-seller.submit-error'));
            }
            setSubmittedState(false);
        },
        function (error) {
            var errors = [];
            if (error.responseJSON && error.responseJSON.error && error.responseJSON.error.errors) {
                for (var field in error.responseJSON.error.errors) {
                    errors.push(error.responseJSON.error.errors[field]);
                }
            }
            if (!errors.length && error.responseJSON && error.responseJSON.message) {
                errors.push(error.responseJSON.message);
            }
            $(".error-container", messageSellerModal).removeClass('d-none').text(errors.join(' '));
            setSubmittedState(false);
            logExperimentEvent('fail');
        },
        'POST',
        Bark.apiVersionHeader('v2')
    );
}

/**
 * Validates the message form prior to submission.
 */
function validateMessageForm() {
    resetMessageValidationMessages();
    setSubmittedState(true);
    phoneToValidate = "";
    emailToValidate = "";
    // Reset this message in case it was changed by a previous validation attempt.
    $(".email-row .invalid-feedback").text(_t('public_seller-profile:message-seller.invalid-email-error'));

    if (contactInfoRequired) {
        phoneToValidate = $("input[name=phone]", messageSellerModal).val() || "";
        emailToValidate = $("input[name=email]", messageSellerModal).val() || "";
    }
    // Basic local validation
    if (contactInfoRequired && !emailToValidate.match(/.+@.+\..+/)) {
        showMessageValidationError("email");
        setSubmittedState(false);
        logEvent('validation_error_email', emailToValidate);
    }

    if (contactInfoRequired && !$("input[name=name]", messageSellerModal).val()) {
        showMessageValidationError("name");
        setSubmittedState(false);
        logEvent('validation_error_name', "");
    }

    if (!$("textarea[name=message]", messageSellerModal).val()) {
        showMessageValidationError("message");
        setSubmittedState(false);
        logEvent('validation_error_message', "");
    }

    // If no validation errors, proceed with any external validations
    if (invalidFieldCount === 0) {
        validatePhoneOrContinue();
    } else {
        logExperimentEvent('fail')
    }
}

/**
 * Validates the email address externally. If not present, skips this validation
 */
function validateEmailOrContinue() {
    if (!emailToValidate.length) {
        // No email address to validate, move on to submit the form
        return submitMessageForm();
    }
    $("[name=email]", messageSellerModal).email_validator({
        success: function (data) {
            if (data.is_valid) {
                submitMessageForm();
            } else {
                if (data.did_you_mean) {
                    $(".email-row .invalid-feedback").html(
                        _t('common_contact-seller-modal:email-suggestion', {
                            did_you_mean_suggestion: `<a class="email-did-you-mean" href="#">${data.did_you_mean}</a>`,
                        }),
                    );
                    $(".email-did-you-mean", messageSellerModal).one("click", function (e) {
                        $("[name=email]", messageSellerModal).val(data.did_you_mean);
                        resetMessageValidationMessages();
                        e.stopPropagation();
                        return false;
                    });
                    logEvent('validation_error_email_external', emailToValidate);
                }
                showMessageValidationError("email");
                setSubmittedState(false);
                logExperimentEvent('fail')
            }
        },
        error: function () {
            showMessageValidationError("email");
            setSubmittedState(false);
            logExperimentEvent('fail')
        }
    });
}

/**
 * Validates the phone number externally. If not present, skips this validation
 */
function validatePhoneOrContinue() {
    if (!phoneToValidate.length) {
        // No phone number to validate, move on to the email address
        return validateEmailOrContinue();
    }
    Bark.validate.ukTel(phoneToValidate, function (success, moderate) {
        if (success || (!success && moderate)) {
            validateEmailOrContinue();
        } else {
            showMessageValidationError("phone");
            setSubmittedState(false);
            logEvent('validation_error_phone_external', phoneToValidate);
            logExperimentEvent('fail');
        }
    }, false, 'buyer');
}

export default function() {
    messageSellerModal = $("#messageSellerModal");

    // Message button event handler
    $("body").on("click.show", ".js-message-seller", function () {
        // Ensure that a seller profile ID data element has been set
        sellerProfileId = $(this).data("seller-profile-id") ? $(this).data("seller-profile-id").toString() : 0;
        projectId = $(this).data("project-id") || "";
        if (sellerProfileId.length) {
            // Add the company name to the confirmation message text, if provided.
            var companyName = $(this).data('seller-name') || '';
            companyName = companyName.length ? ' to ' + companyName : '';
            $(".confirmation-message .company-name", messageSellerModal).text(companyName);

            // Clear any previous error messages
            resetMessageValidationMessages();

            // Show the modal
            $("#messageSellerModal").modal("show");
            logExperimentEvent('view');
        } else {
            // This should never happen, so inform Bugsnag
            bugsnagClient.notify(new Error("js-message-seller.click:failed"), {
                severity: "error"
            });
        }
    });

    // Reset the form before the modal is shown
    messageSellerModal.on("show.bs.modal.reset", function () {
        toggleConfirmationMessage(false);
    });

    // Focus the visible field when the modal is shown, if on desktop
    messageSellerModal.on("shown.bs.modal.focus", function () {
        let sourceType = $('.seller-public-profile-page').length > 0 ? 'seller-profile' : '';
        initExperimentTracking({sourceType, sellerProfileId, projectId});
        focusMessageField();
        logEvent('show_modal', sellerProfileId);
    });

    // Log the modal closing
    messageSellerModal.on("hidden.bs.modal.focus", function () {
        logEvent('hide_modal', sellerProfileId);
    });

    // Submit button handler
    $(".js-post-message", messageSellerModal).on("click", function (e) {
        logExperimentEvent('submit')
        validateMessageForm();
        e.preventDefault();
        return false;
    });

    if (Bark.GET('amp_message')) {
        $('.js-message-seller').eq(0).click();
        logEvent('show_modal_from_amp', sellerProfileId);
    }

}

